
let avalancheCache = {};

export const getAvalancheData = async (date) => {
   const formattedDate = date.format('YYYY-MM-DD');

   // Überprüfen, ob der Typ bereits im Cache vorhanden ist und die Daten aktuell sind
   if (avalancheCache[formattedDate]) {
      return avalancheCache[formattedDate];
   }

   if (Object.keys(avalancheCache).length > 20) {
      avalancheCache = {};  
   }

   const urls = [
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-CH.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-AT-02.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-AT-03.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-AT-04.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-AT-05.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-AT-06.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-AT-07.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-AT-08.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-DE-BY.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-IT-21.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-IT-23.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-IT-25.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-IT-32-BZ.json`,
      `https://static.avalanche.report/eaws_bulletins/${formattedDate}/${formattedDate}-IT-32-TN.json`
   ];

   try {
      const fetchPromises = urls.map(url => fetch(url).then(response => response.json()));
      const results = await Promise.all(fetchPromises);
      
      // Alle bulletins sammeln
      let allBulletins = [];
      results.forEach(data => {
         if (data && data.bulletins) {
            allBulletins = allBulletins.concat(data.bulletins);
         }
      });

      // Cache aktualisieren
      avalancheCache[formattedDate] = allBulletins;
      return allBulletins;
   } catch (error) {
      console.error('Error querying data: ', error);
      return { "error": error.toString() };
   }
};

