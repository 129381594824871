import React from 'react';
import Grid from '@mui/material/Grid2';
import { snowIconLabels } from '../utils';
import SnowIcon from './SnowIcon';
import MultiLevelRose from '../MultiLevelRose';

function SnowTypeIcon({ type }) {
   return (
      <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Ubuntu' }}>
         <SnowIcon snowType={type} style={{ fontSize: 30 }} />
         <span style={{ marginLeft: '10px', fontSize: '16px' }}>{snowIconLabels[type]}</span>
      </div>
   );
}

export function SnowConditionEntry(props) {
   return (
      <>
         {props.isFirstEntry ?
            <Grid size={{ xs: 12 }} style={{ padding: '0px', marginTop: '5px' }}></Grid>
            :
            <Grid size={{ xs: 12 }} style={{ padding: '0px', marginTop: '5px' }}>
               <hr style={{ border: '1px solid black', margin: '0 0 10px 10px' }} />
            </Grid>
         }


         <div>
            <Grid container >
               <Grid size={{ xs: 12, md: 5 }} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                  <SnowTypeIcon type={props.snowType} />
               </Grid>
               <Grid container size={{ xs: 12, md: 7 }} >
                  <Grid size={{ xs: 12 }} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                     <MultiLevelRose conditionData={props.snowConditionData} cssIdentifier={props.cssIdentifier} width={"120px"} height={"100px"} />
                  </Grid>
               </Grid>
            </Grid>
         </div>
      </>
   );
}

export default SnowConditionEntry;