import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { CiGlobe } from 'react-icons/ci'; // Import des Weltkugel-Icons

import './common.css';
import 'typeface-ubuntu';

function Navigation() {
   return (
      <>
      <div className="header-div">
         <Navbar expand="lg" style={{"padding": "0"}} className="navbar-custom navbar-font">
            <Container fluid>
               {/* Logo auf der linken Seite */}
               <Navbar.Brand href="#home" style={{ marginLeft: '3%' }}>
                  <img
                     src="SchneeReport_Logo.png"
                     alt="SchneeReport Logo"
                     height="40" // Passen Sie die Höhe nach Bedarf an
                     className="d-inline-block align-top"
                  />
               </Navbar.Brand>

               {/* Navbar Toggle für mobile Ansicht */}
               <Navbar.Toggle aria-controls="basic-navbar-nav" />

               {/* Navbar Collapse für responsive Navigation */}
               <Navbar.Collapse id="basic-navbar-nav">
                  {/* Linke Seite der Navbar */}
                  <Nav className="me-auto d-flex align-items-center">
                     {/* Vertikale Linie als Trennung */}
                     <div className="navbar-divider"></div>

                     {/* Navigationselemente mit fettem Text */}
                     <Nav.Link href="/" className="fw-bold fs-5 nav-link-spacing">SchneeReport</Nav.Link>
                     <Nav.Link href="/alpinemeteo" className="fw-bold fs-5 nav-link-spacing">AlpineMeteo</Nav.Link>
                     <Nav.Link href="/avalanchereport" className="fw-bold fs-5 nav-link-spacing">Lawinenlage</Nav.Link>
                  </Nav>

                  {/* Rechte Seite der Navbar */}
                  <Nav className="ms-auto d-flex align-items-center">
                     {/* Vertikale Linie als Trennung */}
                     <div className="navbar-divider"></div>

                     {/* Sprachauswahl mit Weltkugel-Icon */}
                     <NavDropdown
                        title={<CiGlobe size={30} />}
                        id="language-dropdown"
                        align="end"
                     >
                        <NavDropdown.Item href="#language/de">Deutsch</NavDropdown.Item>
                        <NavDropdown.Item href="#language/en">English</NavDropdown.Item>
                        <NavDropdown.Item href="#language/fr">Français</NavDropdown.Item>
                     </NavDropdown>
                  </Nav>
               </Navbar.Collapse>
            </Container>
         </Navbar >
         <div style={{ "width": "98%", "borderBottom": "1px solid #ff5159", "marginLeft": "1%"}}></div>
         </div>
      </>
   );
}

export default Navigation;