import React from "react";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Container, Row, Col } from "react-bootstrap";
import AvalancheIcon from '../AvalancheIcon';
import { avalancheIconLabels } from '../utils';

function getAvailableAvalancheConditions(reports) {
   let ret = new Set();
   for (const rid in reports) {
      const ava = reports[rid].avalanche;
      for (const aid in ava) {
         ret.add(ava[aid].problem);
      }
   }
   return ret;
}


const AvalancheIconBar = (props) => {
   const availableConditions = getAvailableAvalancheConditions(props.reports);
   if (availableConditions.size === 0) {
      return <></>;
   }

   return (
      <Container>
         <span style={{ fontSize: "14px" }}>Regionen mit bestimmten Lawinenproblemen anzeigen</span>
         <Row className="justify-content-center">
            <Col xs={12}>
               <Row>
                  {Object.entries(avalancheIconLabels).map((elem) => (
                     <Col xs={6} sm={4} md={2} className="mb-2" style={{border: (props.selection==elem[0])?"1px solid black":"1px solid white"}}>
                        <Tooltip title={elem[1]}>
                           <Button
                              onClick={() => props.handleSelection(elem[0])}
                              color="black"
                              fullWidth
                              disabled={!availableConditions.has(elem[0])} >
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                 <AvalancheIcon avalancheType={elem[0]} style={{ height: "40px", filter: availableConditions.has(elem[0]) ? "" : "grayscale(100%)" }} />
                                 <span style={{ fontSize: "10px" }}>{elem[1]}</span>
                              </div>
                           </Button>
                        </Tooltip>
                     </Col>
                  ))}
               </Row>
            </Col>
         </Row>
      </Container>
   );
};

export default AvalancheIconBar;
