import { ReactSVG } from 'react-svg';
import { getExpoList } from './utils';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './common.css';

function getAltitudeData(range) {
   const [from, to] = range;
   let description = '';
   let layer = [];

   if (from && to) {
      layer = ["middle"]
      description = `Von ${from}m bis ${to}m`;
   } else if (from) {
      layer = ["top"];
      description = `Ab ${from}m`;
      if (from == "treeline") {
         description = `Ab Baumgrenze`;
      }
   } else if (to) {
      layer = ["bottom"];
      description = `Bis ${to}m`;
      if (to == "treeline") {
         description = `Bis Baumgrenze`;
      }
   } else {
      layer = ["bottom", "middle", "top"];
      description = 'Alle Höhenlagen';
   }
   return [layer, description];
}

function getExposures(expo) {
   if (expo && expo.includes("all")) {
      return ["N", "E", "W", "S", "NE", "NW", "SE", "SW"];
   }
   return expo;
}

const defaultOpacities = {
   "top": "1.0",
   "middle": "0.95",
   "bottom": "0.90"
};

const defaultColors = {
   "flat": "#ddcfe2",
   "side": "#bb9ec5"
};

function deactivateLayer(cssId) {
   const elementsDel = document.querySelectorAll('.' + cssId);
   elementsDel.forEach((element) => {
      element.classList.remove(cssId);
   });
}

function deactivateAllLayers() {
   deactivateLayer("multirose-flat-top");
   deactivateLayer("multirose-flat-middle");
   deactivateLayer("multirose-flat-bottom");
   deactivateLayer("multirose-side-top");
   deactivateLayer("multirose-side-middle");
   deactivateLayer("multirose-side-bottom");
}

function switchLayer(cssId) {
   const elements = document.querySelectorAll('.' + cssId);
   elements.forEach((element) => {
      const regex = /^mlr_(top|middle|bottom)_(N|E|S|W|NE|NW|SE|SW)(_1|_2)?-\d+$/;
      const match = element.id.match(regex);
      if (match) {
         const res = {
            layer: match[1],      // top, middle oder bottom
            direction: match[2],   // N, E, S, W, NE, NW, SE, SW
            face: match[3] ? "side" : "flat" // _1, _2 oder null, falls nicht vorhanden
         };
         const className = "multirose-" + res.face + "-" + res.layer;
         if (element.classList.contains(className)) {
            element.classList.remove(className);
         } else {
            element.classList.add(className);
         }
      }
   });
}

function MultiLevelRose(props) {
   console.log(props)
   const conditionData = props.conditionData;

   const faceColorStyle = props.faceColorStyle ? props.faceColorStyle : defaultColors;
   const faceOpacityStyle = props.faceOpacityStyle ? props.faceOpacityStyle : defaultOpacities;

   const wd = props.width ? props.width : "50px";
   const he = props.height ? props.height : "50px";
   const regex = /^mlr_(top|middle|bottom)_(N|E|S|W|NE|NW|SE|SW)(_1|_2)?-\d+$/;

   return (
      <>
         <div style={{ justifyContent: 'center', width: "100%", display: 'flex', alignItems: 'center', fontFamily: 'Ubuntu', fontWeight: 500, fontSize: "14px" }}>
            <div style={{ margin: '10px' }}>
               <ReactSVG
                  src="./multi_level_rose.svg"
                  afterInjection={(svg) => {
                     svg.setAttribute("width", wd);
                     svg.setAttribute("height", he);
                     svg.querySelectorAll('[id]').forEach((element) => {
                        const id = element.id;
                        const match = id.match(regex);
                        if (match) {
                           const res = {
                              layer: match[1],      // top, middle oder bottom
                              direction: match[2],   // N, E, S, W, NE, NW, SE, SW
                              face: match[3] ? "side" : "flat" // _1, _2 oder null, falls nicht vorhanden
                           };
                           for (const idx in props.conditionData) {
                              const [fromAltitude, toAltitude, expo, prefix] = props.conditionData[idx];
                              const exposures = getExposures(getExpoList(expo));
                              const altData = getAltitudeData([fromAltitude, toAltitude]);
                              if (altData[0].includes(res.layer) && exposures.includes(res.direction)) {
                                 const opac = faceOpacityStyle[res.layer];
                                 const color = faceColorStyle[res.face];
                                 element.setAttribute("fill", color);
                                 element.setAttribute("opacity", opac);
                                 element.classList.add(props.cssIdentifier + "-" + idx);
                              }
                           }
                        }
                     });
                  }}
               />
            </div>
            <div style={{ width: "60%", marginLeft: "10px" }}>

               <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                     {props.conditionData.map(([fromAltitude, toAltitude, expo, prefix], idx) => (
                        <>
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    size="small"
                                    onChange={() => switchLayer(props.cssIdentifier + "-" + idx)}
                                    checkedIcon={<VisibilityIcon />}
                                    icon={<VisibilityIcon color={"grey"} />}
                                 />
                              }
                              label={
                                 <Typography sx={{ marginTop: "2px", fontSize: 14, fontFamily: "Ubuntu" }}>
                                    {(prefix?prefix+": ":"")+getAltitudeData([fromAltitude, toAltitude])[1]}
                                 </Typography>
                              }
                           />
                        </>
                     ))}
                  </FormGroup>
               </FormControl>
            </div>
         </div>
      </>
   );
}

export default MultiLevelRose;
