import React, { useState, useEffect } from 'react';
import MarkerClusterGroup from 'react-leaflet-cluster'
import { AttributionControl, MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import { getLatestDataForStation, getStationMetadata } from './query';
import LocateControl from '../LocateControl';
import BadgeMarker from './BadgeMarker';
import StationInfoBox from './StationInfoBox';
import LegendSelector from './LegendSelector';
import ColorSelector from './ColorSelector';
import { tlurl } from '../utils';
import * as L from 'leaflet';

import 'leaflet.vectorgrid';
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

const AlpinemeteoMap = (props) => {
   const [activeStationId, setActiveStationId] = useState(null);
   const [activeStationData, setActiveStationData] = useState(null);
   const [infoBoxVisible, setInfoBoxVisible] = useState(false);

   useEffect(() => {
      if (activeStationId) {
         // fetch latest data (not required for debounced date)
         const latestDataForStationQuery = async () => {
            const latestData = await getLatestDataForStation(activeStationId);
            const metaData = await getStationMetadata(activeStationId);
            if (latestData && metaData) {
               setActiveStationData({
                  metadata: metaData,
                  data: latestData.data
               });
               props.selectStation(metaData);
            }
         };
         latestDataForStationQuery();
      } else {
         setActiveStationData(null);
      }
   }, [activeStationId]);

   const closeBox = () => {
      setInfoBoxVisible(false);
      setActiveStationData(null);
      props.selectStation(null);
   }
   const showBox = () => {
      setInfoBoxVisible(true);
   }

   const currentEpoch = Math.floor(new Date().getTime() / 1000);
   const prevEpoch = currentEpoch - 7 * 24 * 60 * 60;
   // epoch for now and now-7d

   const stations = props.stations;

   L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

   function getUnit(filt) {
      switch (filt) {
         case "vw": return "km/h";
         case "vwmax": return "km/h";
         case "hs": return "cm";
         case "hs24h": return "cm";
         case "ta": return "°C";
         case "tss": return "°C";
         default: return "";
      }
      return "";
   }

   function getElemValue(elem) {
      switch (props.stationFilter) {
         case "vw": return elem["_vw"];
         case "vwmax": return elem["_vwmax"];
         case "hs": return elem["_hs"];
         case "hs24h": return elem["_hsd"];
         case "ta": return elem["_ta"];
         case "tss": return elem["_tss"];
         case "station": return elem["_altitude"];
      }
      return "";
   }

   const colorSelector = new ColorSelector();
   const paramUnit = getUnit(props.stationFilter);
   const hasLastSeenProperty = stations && stations[0] && stations[0].hasOwnProperty("_lastseen");

   return (
      <div className={"custom-map-div"}>
         <MapContainer
            center={[47.2, 11.0]}
            zoom={L.Browser.mobile ? 8 : 9}
            style={{ width: '100%', height: '585px' }}
            attributionControl={false}
            className="custom-map"
            scrollWheelZoom={false}
            gestureHandling={true}
            maxZoom={12}
         >
            <TileLayer
               url={tlurl}
               attribution='CC by avalanche.report'
            />
            <AttributionControl position="bottomright" prefix={false} />
            <LocateControl />
            {hasLastSeenProperty &&
               <>
                  <LayersControl position="topright">
                     <LayersControl.Overlay name={"Zeige inaktive Stationen"}>
                        <MarkerClusterGroup disableClusteringAtZoom={12} maxClusterRadius={50}>
                           {stations.map((elem) => (
                              elem["_lastseen"] < prevEpoch ? (
                                 <BadgeMarker
                                    color={colorSelector.selectColor(props.stationFilter, getElemValue(elem))}
                                    position={[elem["_latitude"], elem["_longitude"]]}
                                    eventHandlers={{
                                       click: () => {
                                          if (activeStationId == elem["_internalid"]) {
                                             return;
                                          }
                                          closeBox();
                                          setActiveStationId(elem["_internalid"]);
                                          showBox();
                                       }
                                    }}
                                    unit={""}
                                    content={""} />
                              ) : null))}
                        </MarkerClusterGroup>
                     </LayersControl.Overlay>
                  </LayersControl>
                  <MarkerClusterGroup disableClusteringAtZoom={12} maxClusterRadius={50}>
                     {stations.map((elem) => (
                        <BadgeMarker
                           key={elem["_internalid"] + "-marker"}
                           color={colorSelector.selectColor(props.stationFilter, getElemValue(elem))}
                           position={[elem["_latitude"], elem["_longitude"]]}
                           eventHandlers={{
                              click: () => {
                                 if (activeStationId == elem["_internalid"]) {
                                    return;
                                 }
                                 closeBox();
                                 setActiveStationId(elem["_internalid"]);
                                 showBox();
                              }
                           }}
                           unit={""}
                           content={""} />
                     ))}
                  </MarkerClusterGroup>
               </>
            }
            {!hasLastSeenProperty &&
               stations.map((elem) => (
                  <BadgeMarker
                     key={elem["_internalid"] + "-marker"}
                     color={colorSelector.selectColor(props.stationFilter, getElemValue(elem))}
                     position={[elem["_latitude"], elem["_longitude"]]}
                     eventHandlers={{
                        click: () => {
                           if (activeStationId == elem["_internalid"]) {
                              return;
                           }
                           closeBox();
                           setActiveStationId(elem["_internalid"]);
                           showBox();
                        }
                     }}
                     unit={paramUnit}
                     content={getElemValue(elem)} />
               ))
            }
            <LegendSelector type={props.stationFilter} />
            <StationInfoBox infoBoxVisible={infoBoxVisible} stationData={activeStationData} closeBox={closeBox} />
         </MapContainer>
      </div>
   );
};

export default AlpinemeteoMap;
