import React from 'react';
import Grid from '@mui/material/Grid2';

import SnowIconBar from './SnowIconBar';
import AvalancheIconBar from './AvalancheIconBar';
import './snowreport.css';
import ReportPaper from './ReportPaper';


function Report(props) {

   return (
      <>
         <div style={{ maxWidth: "1100px", marginLeft: "auto", marginRight: "auto" }} >
            <Grid container>
               <Grid size={{ xs: 12 }} className="d-flex justify-content-center" >
                  <div style={{ margin: '10px' }} >
                     <SnowIconBar selection={props.snowSelection} handleSelection={props.snowHandleSelection} reports={props.entries} />
                  </div>
               </Grid>
               <Grid size={{ xs: 12 }} className="d-flex justify-content-center" >
                  <div style={{ margin: '10px' }} >
                     <AvalancheIconBar selection={props.avaSelection} handleSelection={props.avaHandleSelection} reports={props.entries} />
                  </div>
               </Grid>
               {props.entries.length > 0 && props.entries.map((entry, index) => (
                  <Grid size={{ xs: 12 }} className="d-flex justify-content-center" >
                     <ReportPaper entry={entry} />
                  </Grid>
               ))}
            </Grid>
         </div>
      </>
   );
}

export default Report;
