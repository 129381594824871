import React, { useState, useEffect, useRef } from 'react';
import { AttributionControl, MapContainer, TileLayer, useMap } from 'react-leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import LocateControl from '../LocateControl';
import VectorTileLayer from './VectorTileLayer';
import ReportInfoBox from './ReportInfoBox';
import { tlurl, formatTimestampToUserLocale } from '../utils';
import { decodeHTMLEntities, getNameForRegionId, fixBulletinID, formatName } from './utils';
import * as L from 'leaflet';

import 'leaflet.vectorgrid';
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import './avalanchereport.css';

function truncateText(text, maxWords) {
   const words = text.split(' '); 
   if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...'; 
   }
   return text; // Wenn der Text kürzer ist, den ursprünglichen Text zurückgeben
}

const AvalanchereportMap = (props) => {
   const [activeReport, setActiveReport] = useState({ "hidden": true });

   const showInfoBox = (region) => {
      if (!region) {
         setActiveReport({ "hidden": true });
         props.setSelectedBulletin(null);
         return;
      }
      const bulletin = props.data.getBulletinForRegion(region);
      const name = formatName(decodeHTMLEntities(getNameForRegionId(region)));
      const problems = props.data.getAvalancheProblems(region, props.amPmSelectorValue);
      if (!bulletin || !name || name == "Null") {
         setActiveReport({ "hidden": true });
         props.setSelectedBulletin(null);
         return;
      }
      const res = {
         "date": formatTimestampToUserLocale(bulletin.publicationTime),
         "title": bulletin.avalancheActivity ? truncateText(decodeHTMLEntities(bulletin.avalancheActivity.highlights),10) : "",
         "regionName": name,
         "avalanche": problems
      };
      setActiveReport(res);
      props.setSelectedBulletin(bulletin);
      return;
   };

   L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);


   return (
      <div className={"custom-map-div"}>
         <MapContainer
            center={[47.2, 11.0]}
            zoom={L.Browser.mobile ? 8 : 9}
            style={{ width: '100%', height: '585px' }}
            attributionControl={false}
            className="custom-map"
            scrollWheelZoom={false}
            gestureHandling={true}
         >
            <TileLayer
               url={tlurl}
               attribution='CC by avalanche.report'
            />
            <AttributionControl position="bottomright" prefix={false} />
            <LocateControl />
            {props.data && <VectorTileLayer amPmSelectorValue={props.amPmSelectorValue} vectorGridInitializer={props.vectorGridInitializer} data={props.data} problems={props.problems} showInfoBox={showInfoBox} />}
            <ReportInfoBox report={activeReport} showInfoBox={showInfoBox} />
         </MapContainer>
      </div>
   );
};

export default AvalanchereportMap;
