import React from "react";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Container, Row, Col } from "react-bootstrap";
import SnowIcon from './SnowIcon';
import { snowIconLabels } from '../utils';

function getAvailableSnowConditions(reports) {
   let ret = new Set();
   for (const rid in reports) {
      const snow = reports[rid].snow;
      for (const sid in snow) {
         ret.add(snow[sid].snow_type);
      }
   }
   return ret;
}

const SnowIconBar = (props) => {
   const availableConditions = getAvailableSnowConditions(props.reports);
   if (availableConditions.size === 0) {
      return <></>;
   }

   return (
      <Container>
         <span style={{ fontSize: "14px" }}>Regionen mit bestimmten Schneearten anzeigen</span>
         <Row className="justify-content-center">
            <Col xs={12}>
               <Row>
                  {Array.from(availableConditions).map(elem => (
                     <Col xs={6} sm={4} md={2} className="mb-2" style={{ border: (props.selection == elem) ? "1px solid black" : "1px solid white" }}>
                        <Tooltip title={snowIconLabels[elem]}>
                           <Button
                              onClick={() => props.handleSelection(elem)}
                              color="black"
                              fullWidth
                              disabled={!availableConditions.has(elem)} >
                              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                 <SnowIcon snowType={elem} size={40} />
                                 <span style={{ fontSize: "10px" }}>{snowIconLabels[elem]}</span>
                              </div>
                           </Button>
                        </Tooltip>
                     </Col>
                  ))}
               </Row>
            </Col>
         </Row>
      </Container>
   );
};

export default SnowIconBar;
