import { regionNames, bulletinProblemTranslation } from "./utils";

export default class AvalancheBulletinHelper {
   constructor(data) {
      this.bulletinArr = data ? data : [];
      this.maxDangerRatingsWithElevationCache = {};
      this.amPmDifference = false;
   }

   getBulletins() {
      return this.bulletinArr;
   }

   hasAmPmDifference() {
      return this.amPmDifference;
   }

   getBulletinForRegion(regId) {
      for (let i = 0; i < this.bulletinArr.length; i++) {
         const bulletin = this.bulletinArr[i];
         const { regions, avalancheActivity } = bulletin;
         if (regions.some((region) => region.regionID === regId)) {
            return bulletin;
         }
      }
      return null;
   }

   // Methode für max DangerRating per Region und Zeitperiode
   getMaxDangerRatingsByRegion() {
      const regionDangerMap = new Map();

      for (let i = 0; i < this.bulletinArr.length; i++) {
         const bulletin = this.bulletinArr[i];
         const { dangerRatings, regions } = bulletin;
         regions.forEach((region) => {
            const regionId = region.regionID;

            if (!regionDangerMap.has(regionId)) {
               regionDangerMap.set(regionId, { all_day: null, earlier: null, later: null });
            }

            dangerRatings.forEach((rating) => {
               const { mainValue, validTimePeriod } = rating;
               const currentMax = regionDangerMap.get(regionId)[validTimePeriod];
               const numericMainValue = this.selectDangerRating(mainValue);

               // Update the max value only if the new one is more severe
               if (!currentMax || currentMax < numericMainValue) {
                  regionDangerMap.get(regionId)[validTimePeriod] = numericMainValue;
               }
            });
         });
      }
      return regionDangerMap;
   }

   // Methode, die für eine Region und Zeitperiode Lawinenprobleme zurückgibt
   getAvalancheProblems(regionID, validTimePeriod = null) {
      const problems = [];
      let regionSeen = false;

      for (let i = 0; i < this.bulletinArr.length; i++) {
         const bulletin = this.bulletinArr[i];
         const { regions, avalancheProblems } = bulletin;

         if (regions.some((region) => region.regionID === regionID)) {
            regionSeen = true;
            avalancheProblems.forEach((problem) => {
               const pt = problem.problemType;
               if(pt=="favourable_situation"||pt=="no_distinct_avalanche_problem"||pt=="cornices") {
                  return;
               }
               if (this.amPmDifference) {
                  if(problem.validTimePeriod === validTimePeriod) {
                     problems.push(problem);
                  }
               } else {
                  problems.push(problem);
               }
            });
         }
      }
      if(!regionSeen) {
         const lastHyphenIndex = regionID.lastIndexOf('-');
         if (lastHyphenIndex === -1) {
            return problems; // Wenn die Region in keinem Bulletin vorkommt
         } else {
            const parentRegionID = regionID.substring(0, lastHyphenIndex);
            return this.getAvalancheProblems(parentRegionID, validTimePeriod);
         }
      }
      for (let i = 0; i < problems.length; i++) {
         problems[i]["problem"] = bulletinProblemTranslation[problems[i]["problemType"]];
      }
      return problems;
   }

   // Methode, die für eine gegebene Region alle Regionen aus demselben Bulletin zurückgibt
   getRegionsFromSameBulletin(regionID) {
      for (let i = 0; i < this.bulletinArr.length; i++) {
         const bulletin = this.bulletinArr[i];
         const { regions } = bulletin;

         // Suchen, ob die gegebene Region in diesem Bulletin vorkommt
         if (regions.some((region) => region.regionID === regionID)) {
            return regions; // Alle Regionen aus diesem Bulletin zurückgeben
         }
      }
      const lastHyphenIndex = regionID.lastIndexOf('-');
      if (lastHyphenIndex === -1) {
         return null; // Wenn die Region in keinem Bulletin vorkommt
      } else {
         const parentRegionID = regionID.substring(0, lastHyphenIndex);
         return this.getRegionsFromSameBulletin(parentRegionID);
      }
   }

   extendRegions(regionList) {
      for (const reg in regionList) {
         const { regionID } = regionList[reg];
         for (const key in regionNames) {
            // Überprüfe, ob der Key mit "string" beginnt
            if (key.startsWith(regionID) && key != regionID) {
               // Führe Aktion X aus
               regionList.push({ "name": regionNames[key], "regionID": key });
            }
         }
      }
      return regionList;
   }

   getThreshold(f) {
      const regionID = f.id;
      const isITBZ = regionID.startsWith("IT-32-BZ");
      if(isITBZ) {
         return f["elevation line_visualization"];
      }
      const isITSO = regionID.startsWith("IT-25");
      if(isITSO) {
         return 1499;
      }
      if(f["threshold"]) {
         return f["threshold"];
      }
      return 2000;
   }

   // Funktion zur Berechnung der maximalen Warnstufen
   getMaxDangerRatingsForFeature(feature, regionID = feature.id) {
      const debug = regionID=="IT-25-BG-01";
      if(debug) console.log(feature);

      //console.log(feature);
      if (this.maxDangerRatingsWithElevationCache[feature.id]) {
         return this.maxDangerRatingsWithElevationCache[feature.id]
      }
      const threshold = this.getThreshold(feature);
      let filled = false;
      const maxDangerRatings = {
         earlier: null,
         later: null,
         high: null,
         low: null,
         earlier_low: null,
         earlier_high: null,
         later_low: null,
         later_high: null,
         low_high: null,
         earlier_low_high: null,
         later_low_high: null
      };

      for (let i = 0; i < this.bulletinArr.length; i++) {
         const bulletin = this.bulletinArr[i];
         const isRegionPresent = bulletin.regions.some(region => region.regionID === regionID);
         if (isRegionPresent) {
            for (let j = 0; j < bulletin.dangerRatings.length; j++) {
               const rating = bulletin.dangerRatings[j];
               if(debug) console.log(rating);
               const { mainValue, validTimePeriod, elevation } = rating;

               // Wenn der Schwellenwert null ist, verwende denselben Wert für high und low
               if (threshold === null) {
                  this.updateMaxDangerRating(maxDangerRatings, `earlier_low`, validTimePeriod, mainValue, 'earlier');
                  this.updateMaxDangerRating(maxDangerRatings, `earlier_high`, validTimePeriod, mainValue, 'earlier');
                  this.updateMaxDangerRating(maxDangerRatings, `later_low`, validTimePeriod, mainValue, 'later');
                  this.updateMaxDangerRating(maxDangerRatings, `later_high`, validTimePeriod, mainValue, 'later');
               } else {
                  const realLowerBound = (elevation && elevation.lowerBound) ? parseInt(elevation.lowerBound) : "0";
                  const realUpperBound = (elevation && elevation.upperBound) ? parseInt(elevation.upperBound) : "10000";
                  // Bestimme, ob die Gefahr für "high" oder "low" Höhe relevant ist
                  const isHighTl = elevation && elevation.lowerBound && elevation.lowerBound === "treeline";
                  const isLowTl = elevation && elevation.upperBound && elevation.upperBound === "treeline";
                  const isHigh = !isHighTl && realUpperBound > threshold;
                  const isLow = !isLowTl && realLowerBound <= threshold;

                  if(debug) console.log(threshold);
                  if(debug) console.log(realLowerBound);
                  if(debug) console.log(realUpperBound);
                  if(debug) console.log(isHighTl);
                  if(debug) console.log(isLowTl);
                  if(debug) console.log(isHigh);
                  if(debug) console.log(isLow);


                  // Maximalen Danger Rating für earlier_low, earlier_high, later_low und later_high bestimmen
                  if (!elevation || isHigh || isHighTl) {
                     this.updateMaxDangerRating(maxDangerRatings, `earlier_high`, validTimePeriod, mainValue, 'earlier');
                     this.updateMaxDangerRating(maxDangerRatings, `later_high`, validTimePeriod, mainValue, 'later');
                  }
                  if (!elevation || isLow || isLowTl) {
                     this.updateMaxDangerRating(maxDangerRatings, `earlier_low`, validTimePeriod, mainValue, 'earlier');
                     this.updateMaxDangerRating(maxDangerRatings, `later_low`, validTimePeriod, mainValue, 'later');
                  }

                  if(debug) console.log(maxDangerRatings);

               }
            }

            // earlier ist das Maximum von earlier_low und earlier_high
            maxDangerRatings.earlier = Math.max(maxDangerRatings.earlier_low, maxDangerRatings.earlier_high);
            // later ist das Maximum von later_low und later_high
            maxDangerRatings.later = Math.max(maxDangerRatings.later_low, maxDangerRatings.later_high);
            // low ist das Maximum von earlier_low und later_low
            maxDangerRatings.low = Math.max(maxDangerRatings.earlier_low, maxDangerRatings.later_low);
            // high ist das Maximum von earlier_high und later_high
            maxDangerRatings.high = Math.max(maxDangerRatings.earlier_high, maxDangerRatings.later_high);
            // global max
            maxDangerRatings.low_high = Math.max(maxDangerRatings.low, maxDangerRatings.high);
            maxDangerRatings.earlier_low_high = maxDangerRatings.earlier;
            maxDangerRatings.later_low_high = maxDangerRatings.later;

            this.amPmDifference = this.amPmDifference||(maxDangerRatings.earlier!=maxDangerRatings.later);
            filled = true;
         }
      }
      if (filled) {
         this.maxDangerRatingsWithElevationCache[feature.id] = maxDangerRatings;
         //console.log(maxDangerRatings);
         return maxDangerRatings;
      } else {
         const lastHyphenIndex = regionID.lastIndexOf('-');
         if (lastHyphenIndex === -1) {
            return null;
         } else {
            return this.getMaxDangerRatingsForFeature(feature, regionID.substring(0, lastHyphenIndex));
         }
      }
      return null;
   }

   getMaxDangerRatingsForRegionsWithElevation() {
      return this.maxDangerRatingsWithElevationCache;
   }

   getMaxDangerRatingsFromRatings(source) {
      const ratings = source && source["maxDangerRatings"] ? source["maxDangerRatings"] : [];
      Object.entries(ratings).forEach(([key, value]) => {
         if (!key.includes(":")) {
            const maxDangerRatings = {
               earlier: ratings[key + ":am"],
               later: ratings[key + ":pm"],
               high: ratings[key + ":high"],
               low: ratings[key + ":low"],
               earlier_low: ratings[key + ":low:am"],
               earlier_high: ratings[key + ":low:pm"],
               later_low: ratings[key + ":high:am"],
               later_high: ratings[key + ":high:pm"],
               low_high: value
            };
            this.maxDangerRatingsWithElevationCache[key] = maxDangerRatings;
         }
      });
   }

   // Hilfsfunktion zum Aktualisieren des maximalen Danger Ratings
   updateMaxDangerRating(maxDangerRatings, key, validTimePeriod, mainValue, timePeriod) {
      const numericMainValue = this.selectDangerRating(mainValue);
      if (validTimePeriod === timePeriod || validTimePeriod === 'all_day') {
         if (!maxDangerRatings[key] || maxDangerRatings[key] < numericMainValue) {
            maxDangerRatings[key] = numericMainValue;
         }
      }
   }

   selectDangerRating(dr) {
      switch (dr) {
         case "no_rating": return 0;
         case "no_snow": return 0;
         case "low": return 1;
         case "moderate": return 2;
         case "considerable": return 3;
         case "high": return 4;
         case "very_high": return 5;
         default: return 0;
      }
   }
}
