import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import MainSnowreport from './Snowreport/MainSnowreport';
import MainAlpinemeteo from './Alpinemeteo/MainAlpinemeteo';
import MainAvalanchereport from './Avalanchereport/MainAvalanchereport';

import 'dayjs/locale/de';
import 'dayjs/locale/en';

const App = () => {
   dayjs.extend(localeData);
   dayjs.extend(advancedFormat);
   const userLocale = navigator.language || 'de';
   dayjs.locale(userLocale);
   
   return (
      <Router>
         <Routes>
            <Route path="/" exact element={<MainSnowreport />} />
            <Route path="alpinemeteo" exact element={<MainAlpinemeteo />} />
            <Route path="avalanchereport" exact element={<MainAvalanchereport />} />
            <Route path="*" element={<MainSnowreport />} />
         </Routes>
      </Router>
   );
};

export default App;