import React from 'react';
import { PiSnowflake } from 'react-icons/pi';
import { IoIosSunny } from 'react-icons/io';
import { IoRainyOutline } from 'react-icons/io5';
import { WiSandstorm } from 'react-icons/wi';
import { BsLayersHalf } from 'react-icons/bs';
import { GiIceCube } from 'react-icons/gi';

export const snowIcons = {
  powder: PiSnowflake,
  firn: IoIosSunny,
  wet: IoRainyOutline,
  wind: WiSandstorm,
  crust: BsLayersHalf,
  ice: GiIceCube,
};

class SnowIcon extends React.Component {
  getSnowIcon(snowType) {
    const IconComponent = snowIcons[snowType];
    return IconComponent ? <IconComponent size={this.props.size} style={this.props.style} /> : <></>;
  }

  render() {
    const { snowType } = this.props;
    return this.getSnowIcon(snowType);
  }
}

export default SnowIcon;