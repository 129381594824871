import React, { useState, useEffect } from 'react';
import ScrollToTop from "react-scroll-to-top";
import Navigation from '../Navigation';
import SnowreportDateInfoBar from './SnowreportDateInfoBar';
import SnowreportMap from './SnowreportMap';
import Report from './Report';
import { getRegionsAndLatestReports } from './query';
import { setStyleForSnowCondition, setStyleForAvalancheCondition, setStyleForFeature, getRegionById } from '../utils';
import { regionStyling } from './regionStyles';
import dayjs from 'dayjs';

import './snowreport.css';

function MainSnowreport() {
  const [regionData, setRegionData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [date, setDate] = useState(dayjs());
  const [debouncedDate, setDebouncedDate] = useState(date);
  const [snowCond, setSnowCond] = useState((cond) => () => { });
  const [avalancheCond, setAvalancheCond] = useState((cond) => () => { });
  const [avaSelection, setAvaSelection] = useState("");
  const [snowSelection, setSnowSelection] = useState("");

  const vectorGridInitializer = (vectorGrid, featureSet, showInfoBox) => {
    vectorGrid.on('mouseover', function (e) {
      // if the hovered region is known in our database
      if (getRegionById(regionData, e.sourceTarget.properties.id)) {
        setAvaSelection("");
        setSnowSelection("");
        // iterate all features and reset to mouseOut
        featureSet.forEach((val) => {
          setStyleForFeature(regionData, vectorGrid, val, featureSet, regionStyling.mouseOut);
        });
      }
      // set the selected region to mouseOn
      setStyleForFeature(regionData, vectorGrid, e.sourceTarget.properties.id, featureSet, regionStyling.mouseOn);
    });

    vectorGrid.on('mouseout', function (e) {
      // reset the selected region to mouseOut
      setStyleForFeature(regionData, vectorGrid, e.sourceTarget.properties.id, featureSet, regionStyling.mouseOut);
    });

    vectorGrid.on('click', function (e) {
      setAvaSelection("");
      setSnowSelection("");
      // reset the styles to mouseOut
      featureSet.forEach((val) => { setStyleForFeature(regionData, vectorGrid, val, featureSet, regionStyling.mouseOut); });
      // do the click handling
      const reg = getRegionById(regionData, e.sourceTarget.properties.id);
      if (reg) {
        setStyleForFeature(regionData, vectorGrid, e.sourceTarget.properties.id, featureSet, regionStyling.mouseOn);
        showInfoBox(reg);
      } else {
        showInfoBox(null);
      }
    });

    vectorGrid.on('load', function (e) {
      featureSet.forEach((val) => {
        setStyleForFeature(regionData, vectorGrid, val, featureSet, regionStyling.mouseOut);
      });
    });

    const snowCondSet = (cond) => (cond) => {
      featureSet.forEach((val) => { setStyleForFeature(regionData, vectorGrid, val, featureSet, regionStyling.mouseOut); });
      setStyleForSnowCondition(reportData, vectorGrid, cond, featureSet, regionStyling.marked);
    }
    setSnowCond(snowCondSet);

    const avalancheCondSet = (cond) => (cond) => {
      featureSet.forEach((val) => { setStyleForFeature(regionData, vectorGrid, val, featureSet, regionStyling.mouseOut); });
      setStyleForAvalancheCondition(reportData, vectorGrid, cond, featureSet, regionStyling.marked);
    }
    setAvalancheCond(avalancheCondSet);
  };

  const avaHandleSelection = (selectedVal) => {
    if(selectedVal==avaSelection) {
      avalancheCond("");
      setAvaSelection("");
    } else {
      if(selectedVal) {
        setSnowSelection("");
        avalancheCond(selectedVal);
        setAvaSelection(selectedVal)
      } else {
        setAvaSelection("")
      }
    }
  }

  const snowHandleSelection = (selectedVal) => {
    if(selectedVal==snowSelection) {
      snowCond("");
      setSnowSelection("");
    } else {
      if(selectedVal) {
        setAvaSelection("");
        snowCond(selectedVal);
        setSnowSelection(selectedVal)
      } else {
        setSnowSelection("")
      }
    }
  }

  const increaseDate = () => {
    const newDate = date.add(1, 'day');
    if (!newDate.isAfter(dayjs())) {
      setDate(newDate);
    }
  };

  const decreaseDate = () => {
    setDate(date.subtract(1, 'day'));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedDate(date);
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [date]);

  useEffect(() => {
    getRegionsAndLatestReports(debouncedDate)
      .then(([regions, latestReports]) => {
        const reportsArray = Object.values(latestReports).sort((a, b) => dayjs(b.valid_from).diff(dayjs(a.valid_from)));
        setReportData(reportsArray);
        setRegionData(regions);
        console.log(reportsArray);
        console.log(regions);
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der aktuellsten Reports.', error);
      });
  }, [debouncedDate]);

  return (
    <>
      <div>
        <Navigation />
        <SnowreportDateInfoBar
          decreaseDate={decreaseDate}
          increaseDate={increaseDate}
          date={date}
          setDate={setDate} />
        <SnowreportMap
          vectorGridInitializer={vectorGridInitializer}
          regions={regionData}
          reports={reportData} />
        {reportData.length > 0 &&
          <Report
            avaSelection={avaSelection}
            avaHandleSelection={avaHandleSelection}
            snowSelection={snowSelection}
            snowHandleSelection={snowHandleSelection}
            entries={reportData} />
        }
        <ScrollToTop smooth />
      </div>
    </>
  );
}

export default MainSnowreport;
