import dayjs from 'dayjs';

// Funktion zum Abrufen der Regionsdaten
export const dbEndpoint = "https://snowreport-core.cr.baeren-lechtal.com";

export const fetchRegionsData = async (date) => {
   const query = `
     query($filters: ReportFiltersInput) {
       regions {
         documentId
         name
         publishedAt
         regex
         regionImage {
           url
         }
         reports(filters: $filters) {
           documentId
           publishedAt
           valid_from
         }
       }
     }
   `;

   // Der Wert, der von dayjs übergeben wird, um das Filterobjekt zu erstellen
   const variables = {
      filters: {
         valid_from: {
            lte: dayjs(date).endOf('day').toISOString(),  // Endet den Tag und wandelt es in ISO 8601-String um
         },
      },
   };

   let attempts = 0;
   const maxAttempts = 10;
   let regionsData = null;

   while (attempts < maxAttempts) {
      try {
         // Anfrage an den GraphQL-Endpunkt senden
         const response = await fetch(dbEndpoint + '/graphql', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query, variables }),  // Die Variablen an das Query übergeben
         });

         // Antwort in JSON umwandeln
         const { data } = await response.json();

         // Wenn Daten erfolgreich abgerufen wurden, Schleife beenden
         if (data && data.regions) {
            regionsData = data.regions;
            break; // Schleife beenden bei erfolgreichem Abruf
         }
      } catch (error) {
         console.error(`Fehler beim Abrufen der Regionsdaten (Versuch ${attempts + 1} von ${maxAttempts}).`, error);
      }

      attempts += 1;

      // Optional: kurze Pause zwischen den Versuchen (z.B. 500ms)
      await new Promise((resolve) => setTimeout(resolve, 500));
   }

   // Regionsdaten zurückgeben (null, wenn alle Versuche fehlschlagen)
   return regionsData;
};

// Funktion zum Abrufen der detaillierten Reportdaten anhand der documentId
const fetchReportData = async (reportDocId) => {
   const query = `
     query {
       report(documentId: "${reportDocId}") {
         documentId
         title
         content
         images {
           url
         }
         regions {
           documentId
           name
         }
         snow {
           from_altitude
           to_altitude
           snow_type
           expos {
             expo
           }
         }
         avalanche {
           from_altitude
           to_altitude
           problem
           expos {
             expo
           }
         }
         publishedAt
         valid_from
       }
     }
   `;

   let attempts = 0;
   const maxAttempts = 10;
   let reportData = null;

   while (attempts < maxAttempts) {
      try {
         const response = await fetch(dbEndpoint + '/graphql', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query }),
         });

         const { data } = await response.json();

         if (data && data.report) {
            reportData = data.report;
            break; // Beenden der Schleife bei erfolgreichem Abruf
         }
      } catch (error) {
         console.error(`Fehler beim Abrufen des Berichts ${reportDocId} (Versuch ${attempts + 1} von ${maxAttempts}).`, error);
      }

      attempts += 1;

      // Optional: kurze Pause zwischen den Versuchen (z.B. 500ms)
      await new Promise((resolve) => setTimeout(resolve, 500));
   }

   return reportData;
};


export const fetchReportDates = async () => {
   const query = `
   query {
      reports {
       documentId
       title
       valid_from
     }
    }
   `;

   let attempts = 0;
   const maxAttempts = 10;
   let reportsData = null;

   while (attempts < maxAttempts) {
      try {
         // Anfrage an den GraphQL-Endpunkt senden
         const response = await fetch(dbEndpoint + '/graphql', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query }),
         });

         // Antwort in JSON umwandeln
         const { data } = await response.json();

         // Wenn Daten erfolgreich abgerufen wurden, Schleife beenden
         if (data && data.reports) {
            reportsData = data.reports;
            break; // Schleife beenden bei erfolgreichem Abruf
         }
      } catch (error) {
         console.error(`Fehler beim Abrufen der Reportdaten (Versuch ${attempts + 1} von ${maxAttempts}).`, error);
      }

      attempts += 1;

      // Optional: kurze Pause zwischen den Versuchen (z.B. 500ms)
      await new Promise((resolve) => setTimeout(resolve, 500));
   }

   // Regionsdaten zurückgeben (null, wenn alle Versuche fehlschlagen)
   return reportsData;
};


// Hauptfunktion zum Verarbeiten der Regionsdaten und Abrufen der aktuellsten Reports
export const getRegionsAndLatestReports = async (date) => {
   // Schritt 1: Regionsdaten abrufen
   const regions = await fetchRegionsData(date);

   // Map zum Speichern der aktuellsten Reports
   const latestReportsMap = {};
   // Map zum Speichern der Regionen, für die jeder Report gilt
   const reportToRegionsMap = {};

   // Schritt 2: Für jede Region den aktuellsten Report ermitteln
   for (const region of regions) {
      const { reports, documentId: regionDocId, name: regionName, regionImage, regex: regionRegex } = region;

      if (reports && reports.length > 0) {
         // Aktuellsten Report anhand von valid_from finden
         const latestReport = reports.reduce((prev, current) => {
            return new Date(prev.valid_from) > new Date(current.valid_from) ? prev : current;
         });

         // Schritt 3: documentId des aktuellsten Reports sammeln
         latestReportsMap[latestReport.documentId] = null; // Platzhalter, wird später gefüllt

         // Schritt 4: Regionen zuordnen, für die der Report aktuell ist
         if (!reportToRegionsMap[latestReport.documentId]) {
            reportToRegionsMap[latestReport.documentId] = [];
         }
         reportToRegionsMap[latestReport.documentId].push({
            documentId: regionDocId,
            name: regionName,
            regionImage: regionImage,
            regex: regionRegex,
         });
         region["latestReport"] = latestReport.documentId;
      }
   }

   // Schritt 5: Detaillierte Reportdaten für jede documentId abrufen und regions Array anpassen
   const reportIds = Object.keys(latestReportsMap);
   for (const reportDocId of reportIds) {
      const reportData = await fetchReportData(reportDocId);

      // Nur die Regionen behalten, für die der Report aktuell ist
      reportData.regions = reportToRegionsMap[reportDocId];
      reportData["snowData"] = {};
      for(const sid in reportData.snow) {
         const snowEntry = reportData.snow[sid];
         const snowType = snowEntry.snow_type;
         if(!(snowType in reportData["snowData"])) {
            reportData["snowData"][snowType] = [];
         }
         reportData["snowData"][snowType].push([snowEntry.from_altitude, snowEntry.to_altitude, snowEntry.expos]); 
      }
      reportData["avalancheData"] = {};
      for(const aid in reportData.avalanche) {
         const avalancheEntry = reportData.avalanche[aid];
         const avalancheType = avalancheEntry.problem;
         if(!(avalancheType in reportData["avalancheData"])) {
            reportData["avalancheData"][avalancheType] = [];
         }
         reportData["avalancheData"][avalancheType].push([avalancheEntry.from_altitude, avalancheEntry.to_altitude, avalancheEntry.expos]); 
      }
      latestReportsMap[reportDocId] = reportData;
   }

   return [regions, latestReportsMap];
};
