import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

export default function DatePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        disableFuture={true}
        value={props.value}
        onChange={(newValue) => {
          props.setDate(newValue);
        }}
        sx={{
          "& .MuiPickersDay-root": {
            "&.Mui-selected": {
              backgroundColor: "#b84d4d",
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}